import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import imageSrc from '../assets/imgsrc1.jpg';
import Footer from './Footer';
import './Apply.css';

const Apply2 = () => {
  const [fname, setFirstName] = useState("");
  const [lname, setLastName] = useState("");

  // Separate change handlers for fname and lname
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const formData = { 
    fname, lname 
  };

  const sendData = () => {
    fetch("http://localhost:5000/staff", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => console.log("Data sent successfully:", data))
      .catch((error) => console.error("Error sending data:", error));
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the default form submission

    if (formData.fname == ""){
      console.log("empty", fname);
    }
    sendData(); // Call the sendData function
  };

  return (

    <>
    {/* Navbar Section */}
    <div className='Apply-bg'>
      <Navbar />
    </div>



    {/* Apply Container */}
    <div className="apply-containers">
      {/* Image Container */}
      <div className="apply-image-container">
        <img src={imageSrc} alt="Apply" className="apply-image" />
      </div>

      {/* Form Container */}
      <div className="apply-form-container">
        {/* Tab Indicator */}
    

        {/* Form Steps */}
        
        <form className="apply-form" onSubmit={handleSubmit}>
            {/* Form Fields for Tab 1 */}
            <div className="apply-form-groups">
              {/* Name Fields */}
              <div className="apply-form-group">
                <label htmlFor="name" className="apply-label">Name</label>
                <div className="apply-input-row">
                  <div className="apply-input-box">
                    <input
                      type="text"
                      value={formData.fname}
                      onChange={handleFirstNameChange}
                      name="fname"
                      id="firstName"
                      placeholder="First Name"
                      // required
                    />
                  </div>
                  <div className="apply-input-box">
                    <input
                      type="text"
                      value={formData.lname}
                      onChange={handleLastNameChange}
                      name="lname"
                      id="lastName"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="apply-next-button-container">
              <button type="submit" className="apply-button apply-next-button">Submit</button>
            </div>
          </form>
    


          
          


      </div>
    </div>

    <div className="your-component apply">
        {/* Other content */}
        
        <section className="caregiver-info">
          <h2>Become a J & D Healthcare Agency Representative</h2>
          <p>
            As a dedicated caregiver, you will help families restore balance, order, structure, and peace to their lives and assist our elders to remain in their homes. Also, this restores normalcy in their homes. Caregivers are given on-going training, flexible scheduling, and the exciting opportunity to meet new people and foster new meaningful relationships.
          </p>
        </section>

        <footer className="footer-info">
          {/* Adding the phone icon as SVG */}
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
              <path fillRule="evenodd" d="M1.884.511a1.745 1.745 0 0 1 2.611-.163l2.177 2.013c.329.305.445.78.297 1.21L5.417 5.292c-.168.503-.058 1.07.273 1.464l2.099 2.099c.393.393.96.441 1.464.273l1.723-.553c.431-.148.906-.032 1.211.297l2.013 2.177a1.745 1.745 0 0 1-.163 2.611l-1.066 1.066c-.642.642-1.614.86-2.486.544-1.399-.5-3.234-1.837-5.2-3.803C3.35 7.95 2.013 6.114 1.513 4.715.885 2.991 1.97 1.604 1.884.511z"/>
            </svg> 
            Call us today! 704 369 0080
          </p>
        </footer>
      </div>

    <Footer />

    </>


  );
};

export default Apply2;

